<template>
  <div class="modal-card modal-form rounded-10">
    <section class="modal-card-body">
      <div class="content">
        <div class="is-flex is-justify-content-space-between is-align-items-center">
          <div class="title-new-modal" v-if="edit">{{ $t('edit_currency') }}</div>
          <div class="title-new-modal" v-else>{{ $t('new_currency') }}</div>
          <button @click="close" class="delete" aria-label="close"></button>
        </div>
        <section class="pl-20 pr-10 pt-15">
          <div class="columns is-flex is-justify-content-space-between is-align-items-center">
            <div class="column is-5">
              <b-field :label="`${$t('code')}*`">
                <b-input v-model="currency.code" type="text" placeholder=""></b-input>
              </b-field>
              <b-field :label="`${$t('symbol')}*`">
                <b-input v-model="currency.symbol" type="text" placeholder=""></b-input>
              </b-field>
              <b-field :label="`${$t('exchange_rate')}*`">
                <b-input v-model="currency.exchangeRate" type="text" placeholder=""></b-input>
              </b-field>
            </div>
            <div class="column is-flex-direction-column">
              <b-field class="is-flex is-justify-content-flex-end py-15">
                <b-switch :value="false"
                          v-model="currency.isActive"
                          :left-label="true"
                          size="is-medium"
                          type="is-green-success">
                  {{ $t('active') }}
                </b-switch>
              </b-field>
              <b-field class="is-flex is-justify-content-flex-end py-15">
                <b-switch :value="false"
                          v-model="currency.hasCommission"
                          :left-label="true"
                          size="is-medium"
                          type="is-green-success">
                  {{ $t('commission') }}
                </b-switch>
              </b-field>
              <b-field class="is-flex is-justify-content-flex-end py-15">
                <b-switch :value="false"
                          v-model="currency.isCountable"
                          :left-label="true"
                          size="is-medium"
                          type="is-green-success">
                  {{ $t('accounted') }}
                </b-switch>
              </b-field>
              <b-field class="is-flex is-justify-content-flex-end py-15">
                <b-switch :value="false"
                          v-model="currency.isEnable"
                          :left-label="true"
                          size="is-medium"
                          type="is-green-success">
                  {{ $t('enabled') }}
                </b-switch>
              </b-field>
            </div>
          </div>
          <div class="buttons mt-40 is-flex is-align-items-center is-justify-content-flex-end">
            <button @click="save" class="button save is-primary">{{ $t('save') }}</button>
          </div>
        </section>
      </div>
    </section>
  </div>

</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "modalAddCurrency",
  props: {
    edit: {
      type: Boolean,
      default: false
    },
    object: {
      type: Object,
    }
  },
  data() {
    return {
      post: true,
      currency: {
        code: null,
        symbol: null,
        exchangeRate: null,
        isActive: false,
        hasCommission: false,
        isCountable: false,
        isEnable: false
      }
    }
  },
  methods: {
    ...mapActions({
      saveCurrency: 'SAVE_CURRENCY',
      editCurrency: 'EDIT_CURRENCY',
      fetchCurrencies: 'FETCH_CURRENCY'
    }),
    save() {
      this.currency.exchangeRate = Number(this.currency.exchangeRate)
      if (this.edit) {
        this.editCurrency(this.currency)
            .then(() => {
              this.close()
            })
      } else {
        this.saveCurrency(this.currency)
            .then(() => {
              this.close()
            })
      }

    },
    close() {
      this.$emit('close')
      this.$emit('reload')
    }
  },
  created() {
    if (this.edit) {
      this.currency = this.object
    }
  }
}
</script>

<style scoped lang="scss">
.save {
  width: 150px !important;
  border-radius: 10px;
}
</style>