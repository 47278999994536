<template>
  <section>
    <breadcrumb :title="$t('nomenclators')" :subtitle="$t('currencies')"/>
    <div class="card px-10 pb-30 rounded-10">
      <div class="columns filters">
        <div class="column is-flex is-align-items-center">
          <div>
            <b-field>
              <b-input :placeholder="$t('search')"
                       custom-class="search-filter"
                       type="search"
                       icon="magnify"
                       icon-clickable
              >
              </b-input>
            </b-field>
          </div>
          <div class="ml-15">
            <b-icon type="is-pink-light" size="is-medium" icon="close-circle-outline"
                    class="mx-4 cursor-pointer"></b-icon>
          </div>
          <div @click="filter=!filter" class="ml-25">
            <b-icon type="is-purple" size="is-medium" :icon="filter_name"></b-icon>
          </div>
        </div>
        <div class="column is-flex is-justify-content-flex-end mr-10">
          <button @click="isModalCurrencyActive = !isModalCurrencyActive" class="button new is-pink-light">
            <span class="mdi mdi-plus"></span>
            {{ $t('currency') }}
          </button>
        </div>
      </div>
    </div>

    <section class="pt-40">
      <b-table
          :data="desserts"
          ref="table"
          :paginated="false"
          :per-page="itemsPerPage"
          detailed
          :show-detail-icon="false"
          detail-key="ci"
          aria-next-label="Next page"
          aria-previous-label="Previous page"
          aria-page-label="Page"
          aria-current-label="Current page">

        <b-table-column field="name" :label="$t('code')" v-slot="props">
          <template>
            <a class="col name" @click="props.toggleDetails(props.row)">
              {{ props.row.code }}
            </a>
          </template>
        </b-table-column>

        <b-table-column field="symbol" :label="$t('symbol')" sortable v-slot="props">
       <span class="symbol">
         {{ props.row.symbol }}
       </span>
        </b-table-column>

        <b-table-column field="exchange_rate" :label="$t('exchange_rate')" sortable v-slot="props">
        <span class="col exchange_rate">
           {{ props.row.exchangeRate }}
        </span>
        </b-table-column>
        <b-table-column field="active" :label="$t('active')" sortable v-slot="props">
          <div class="data-check">
            <b-checkbox type="is-success" v-model="props.row.isActive"></b-checkbox>
          </div>
        </b-table-column>
        <b-table-column field="accounted" :label="$t('accounted')" sortable v-slot="props">
          <div class="data-check">
            <b-checkbox type="is-success" v-model="props.row.isCountable"></b-checkbox>
          </div>
        </b-table-column>
        <b-table-column field="enabled" :label="$t('enabled')" sortable v-slot="props">
          <div class="data-check">
            <b-checkbox type="is-success" v-model="props.row.isEnable"></b-checkbox>
          </div>
        </b-table-column>
        <b-table-column field="commission" :label="$t('commission')" sortable v-slot="props">
          <div class="data-check">
            <b-checkbox type="is-success" v-model="props.row.hasCommission"></b-checkbox>
          </div>
        </b-table-column>

        <b-table-column :label="$t('actions')" v-slot="props">
          <div class="is-flex">
            <div @click="setPropsModals(props.row)">
              <b-icon type="is-green-accent" icon="pencil" class="mx-4 cursor-pointer"></b-icon>
            </div>
            <div class="mx-10" @click="confirmCustomDelete(props.row.id)">
              <b-icon type="is-danger" icon="trash-can-outline" class="mx-4 cursor-pointer"></b-icon>
            </div>
          </div>

        </b-table-column>

        <template #detail="props">
          <div class="columns">
            <div class="column is-3 is-flex is-flex-direction-column">
              <span class="details-text">{{ $t('id') }}</span>
              <span class="details-text">{{ $t('created') }}</span>
              <span class="details-text">{{ $t('updated') }}</span>
            </div>
            <div class="column is-8 is-flex is-flex-direction-column">
              <span class="details-data">{{ props.row.id }}</span>
              <span class="details-data">{{ props.row.created }}</span>
              <span class="details-data">{{ props.row.updated }}</span>
            </div>
          </div>

        </template>
      </b-table>
      <paginate
          :count="desserts.totalRecords"
          :per_page="desserts.itemsPerPage"
          :current-page="desserts.currentPage"
          :total-pages="desserts.totalPages"
          @first="getLast(true)"
          @previous="getCurrencies(desserts.currentPage - 1,desserts.itemsPerPage)"
          @next="getCurrencies(desserts.currentPage + 1,desserts.itemsPerPage)"
          @last="getLast"
          @perPage="getCurrencies(desserts.currentPage,$event)"
          @search="getCurrencies($event,desserts.itemsPerPage)"

      />

      <b-modal
          v-model="isModalCurrencyActive"
          has-modal-card
          trap-focus
          :destroy-on-hide="false"
          aria-role="dialog"
          aria-label="Example Modal"
          aria-modal>
        <template #default="props">
          <modal-add-currency @reload="getCurrencies(desserts.currentPage,desserts.itemsPerPage)" :object="currency" :edit="edit"
                              @close="props.close"></modal-add-currency>
        </template>
      </b-modal>
    </section>
  </section>
</template>

<script>
import Breadcrumb from "@/components/structure/breadcrumb";
import Paginate from "@/components/list/paginate";
import {mapActions} from "vuex";
import ModalAddCurrency from "@/components/modals/modalAddCurrency";
import {notificationSuccess} from "@/utils";

export default {
  name: "CurrenciesComponent",
  components: {ModalAddCurrency, Paginate, Breadcrumb},
  data() {
    return {
      currency: null,
      edit: false,
      filter_name: 'filter-outline',
      filter: false,
      isModalCurrencyActive: false,
      propsModals: {},
      desserts: [],
      itemsPerPage: 0,
      currentPage: 0,
      totalPages: 0,
      totalRecords: 0,
    }
  },
  methods: {
    ...mapActions({
      fetchCurrencies: 'FETCH_CURRENCY',
      deleteCurrency: 'DELETE_CURRENCY'
    }),
    confirmCustomDelete(id) {
      this.$buefy.dialog.confirm({
        title: 'Eliminando Moneda',
        message: 'Esta seguro que desea <b>eliminar</b> la moneda?',
        confirmText: 'Comfirmar',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.deleteCurrency(id)
              .then(() => {
                notificationSuccess('Moneda eliminada');
                this.getCurrencies(1, 10)
              })
        }
      })
    },
    setPropsModals(props) {
      this.currency = props
      this.edit = true
      this.isModalCurrencyActive = true
    },
    reload() {
      window.location.reload()
    },
    getLast(isFirst = false) {
      let page = isFirst ? 1 : this.desserts.totalPages
      this.getCurrencies(page, this.desserts.itemsPerPage)
    },
    getCurrencies(current, record) {
      let data = {
        currentPage: current,
        recordsPerPage: record,
        id: null,
      };
      this.fetchCurrencies(data)
          .then(value => {
            this.desserts = value
          })
    },
  },
  created() {
    this.getCurrencies(1,10)
  }
}
</script>

<style scoped lang="scss">
.button {
  &.new {
    width: 150px;
    border-radius: 10px;
    font-weight: bold;
    font-size: 20px;
    line-height: 180%;
    letter-spacing: 0.5px;
    color: #FFFFFF;
  }

  .mdi-plus {
    font-size: 38px;
  }
}
</style>